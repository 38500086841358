.AdminList tbody tr:nth-child(odd) {
    background: #f2ebf1;
}

.AdminList .popup-footer {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
}

/* The switch - the box around the slider */
.AdminList .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.AdminList .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.AdminList .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.AdminList .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.AdminList input:checked+.slider {
    background-color: #2196F3;
}

.AdminList input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.AdminList input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.AdminList .slider.round {
    border-radius: 34px;
}

.AdminList .slider.round:before {
    border-radius: 50%;
}

.AdminList tr{
    text-align: center;
}

.AdminList tr:hover {
    cursor: grab;
}

.AdminList tr:active {
    cursor: grabbing;
}
